<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		computed: {},
		created() {
			$(function () {
				var ww = $(window).width();
				console.log(ww);
				if (ww <= 768) {
					console.log('这是wap端~');
					window['adaptive'].desinWidth = 750; //设计稿宽度
					window['adaptive'].baseFont = 28; //默认字体大小
					window['adaptive'].maxWidth = 750; //最大宽度
					window['adaptive'].init();
				} else {
					console.log('这是pc端~');
					window['adaptive'].desinWidth = 750; //设计稿宽度
					window['adaptive'].baseFont = 14; //默认字体大小
					window['adaptive'].maxWidth = 750; //最大宽度
					window['adaptive'].init();
				}
			});

			$(window).resize(function () {
				//当浏览器大小变化时
				// console.log($(window).width());          //浏览器时下窗口可视区域高度
				// console.log($(document).width());        //浏览器时下窗口文档的高度
				// console.log($(document.body).width());   //浏览器时下窗口文档body的高度
				// console.log($(document.body).outerWidth(true)); //浏览器时下窗口文档body的总高度 包括border padding margin
				var ww = $(window).width();
				if (ww <= 768) {
					console.log('变成wap端啦~');
					window['adaptive'].desinWidth = 750; //设计稿宽度
					window['adaptive'].baseFont = 28; //默认字体大小
					window['adaptive'].maxWidth = 750; //最大宽度
					window['adaptive'].init();
				} else {
					console.log('变成pc端啦~');
					window['adaptive'].desinWidth = 750; //设计稿宽度
					window['adaptive'].baseFont = 14; //默认字体大小
					window['adaptive'].maxWidth = 750; //最大宽度
					window['adaptive'].init();
				}
			});
		},
		methods: {},
	};
</script>

<style lang="scss">
	#app {
		max-width: 7.5rem;
		margin: auto;
	}

	.van-button {
		font-size: 0.32rem !important;
	}
</style>
