import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const PERSIST_PATHS = ['title', 'token', 'userInfo'];

export default new Vuex.Store({
  state: {
    title: '',
    token: '',
    userInfo: {},
  },
  getters: {
  },
  mutations: {
    // 设置标题
    SET_TITLE(state, data) {
      state.title = data;
    },
    // 设置token
    SET_TOKEN(state, data) {
      state.token = data;
    },
    // 设置用户信息
    SET_USER_INFO(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    /**
     * 设置标题
     * @param {*} param0 
     * @param {*} data 
     */
    setTitle({ commit }, data) {
      commit('SET_TITLE', data);
    },
    /**
     * 设置token
     * @param {*} param0 
     * @param {*} data 
     */
    setToken({ commit }, data) {
      commit('SET_TOKEN', data);
    },
    /**
     * 设置用户信息
     * @param {*} param0 
     * @param {*} data 
     */
    setUserInfo({ commit }, data) {
      commit('SET_USER_INFO', data);
    },
  },
  modules: {
  },
  plugins: [
    // 持久化储存插件
    createPersistedState({
      storage: window.sessionStorage,
      paths: PERSIST_PATHS
    }),
  ],
})
