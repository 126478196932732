import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'layout',
  //   redirect: '/index',
  //   component: () => import('../views/layout/index.vue'),
  //   children: [
  //     // 首页 ---- 素材库
  //     {
  //       path: '/index',
  //       name: 'home',
  //       component: () => import('../views/home/index.vue')
  //     },
  //     // 分享记录
  //     {
  //       path: '/shareRecord',
  //       name: 'shareRecord',
  //       component: () => import('../views/home/share_record.vue')
  //     },
  //   ]
  // },
  // // 登录
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login/login.vue')
  // },
  // // 注册
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/login/register.vue')
  // },
  // // 注册成功
  // {
  //   path: '/registerSuccess',
  //   name: 'registerSuccess',
  //   component: () => import('../views/login/registerSuccess.vue')
  // },
  // // 发布
  // {
  //   path: '/publish',
  //   name: 'publish',
  //   component: () => import('../views/home/publish.vue')
  // },
  // 素材详情
  {
    path: '/matenialDetail',
    name: 'matenialDetail',
    component: () => import('../views/home/material_detail.vue')
  },
  // 分享出去的素材详情
  {
    path: '/matenialDetail/:pageType/:article_sn',
    name: 'matenialDetail',
    component: () => import('../views/home/material_detail.vue')
  },
  // // 分享访问首页
  // {
  //   path: '/matenial/share/:article_sn',
  //   name: 'shareHome',
  //   component: () => import('../views/home/share_home.vue')
  // },
  // // 分享记录
  // {
  //   path: '/shareRecordPage',
  //   name: 'shareRecord',
  //   component: () => import('../views/home/share_record.vue')
  // },
  // // 访问记录
  // {
  //   path: '/accessRecord',
  //   name: 'accessRecord',
  //   component: () => import('../views/home/access_record.vue')
  // },
  // // 单人记录
  // {
  //   path: '/singleAccessRecord',
  //   name: 'singleAccessRecord',
  //   component: () => import('../views/home/single_access_record.vue')
  // },
  // // vip设置页面
  // {
  //   path: '/vipSetting',
  //   name: 'vipSetting',
  //   component: () => import('../views/home/vip_setting.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
